exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-page-blog-tsx": () => import("./../../../src/templates/blog/pageBlog.tsx" /* webpackChunkName: "component---src-templates-blog-page-blog-tsx" */),
  "component---src-templates-blog-page-post-tsx": () => import("./../../../src/templates/blog/pagePost.tsx" /* webpackChunkName: "component---src-templates-blog-page-post-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("./../../../src/templates/genericPage.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/homePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-legal-page-legal-tsx": () => import("./../../../src/templates/legal/pageLegal.tsx" /* webpackChunkName: "component---src-templates-legal-page-legal-tsx" */),
  "component---src-templates-use-page-tsx": () => import("./../../../src/templates/usePage.tsx" /* webpackChunkName: "component---src-templates-use-page-tsx" */)
}

